import axios from 'axios'
import React, { ChangeEvent, useState } from 'react'

import { UseFormMethods, useForm } from 'react-hook-form'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLS from 'src/config/api/Urls'

import * as S from './styles'

import { Button } from '@interco/inter-ui/components/Button'
import { Input } from '@interco/inter-ui/components/Input'
import { Radio } from '@interco/inter-ui/components/Radio'
import Close from 'inter-frontend-svgs/lib/v2/navigation/close'
import OrangeDSIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import AcceptTerms from '../AcceptTerms/_index'

import * as Types from './types'

const formatStringAsCurrency = (currency: string) => {
  let aux = currency.replace(/\D/g, '')
  aux = aux.replaceAll('R$', '')
  aux = (parseFloat(aux) / 100).toFixed(2) + ''
  aux = aux.replace('.', ',')
  aux = aux.replace(/(\d)(\d{3})(\d{3})(\d{3})(\d{3})(\d{3}),/g, '$1.$2.$3.$4.$5.$6,')
  aux = aux.replace(/(\d)(\d{3})(\d{3})(\d{3})(\d{3}),/g, '$1.$2.$3.$4.$5,')
  aux = aux.replace(/(\d)(\d{3})(\d{3})(\d{3}),/g, '$1.$2.$3.$4,')
  aux = aux.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,')
  aux = aux.replace(/(\d)(\d{3}),/g, '$1.$2,')
  aux = `R$ ${aux}`
  return aux
}

const NewContactForm = ({ closeModal }: Types.NewContactFormProps) => {
  const { register, errors, handleSubmit, setValue }: UseFormMethods<Types.IFormValues> = useForm<Types.IFormValues>()
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ aceite, setAceite ] = useState(false)
  const [ isClienteInter, setIsClienteInter ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ sent, setSent ] = useState(false)
  const [ error, setError ] = useState(false)

  const clearForm = () => {
    setValue('nome', '')
    setValue('telefone', '')
    setValue('email', '')
    setValue('cnpj', '')
    setValue('faturamento', '')
    setAceite(false)
    setIsClienteInter(false)
    setSent(false)
    setError(false)
  }

  const sendForm = async (data: Types.IFormValues) => {
    const formData = {
      campanha: 'API Banking',
      CpfCnpj: data.cnpj?.replaceAll(/[/().R$\- ]/g, ''),
      nome: data.nome,
      email: data.email,
      conteudo01: data.telefone?.replaceAll(/[/().R$\- ]/g, ''),
      conteudo02: data.faturamento?.replaceAll(/[/().R$\- ]/g, ''),
      conteudo03: isClienteInter ? 'Sim' : 'Nao',
      aceite,
    }
    setLoading(true)
    try {
      await axios.post(`${URLS.CONTACT_FORM_POST_V5}/lp-mktcloud-inter`, [ formData ], {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      setLoading(false)
      setSent(true)
    } catch (e) {
      setError(true)
      setLoading(false)
    }
  }

  const closeButton = closeModal && (
    <S.CloseButton
      className='border-0 bg-transparent pt-3 pt-md-4'
      onClick={() => {
        clearForm()
        closeModal()
      }}
    >
      <Close width='24' height='24' color='orange--extra' />
    </S.CloseButton>
  )

  const formulario = !sent && !error && (
    <form
      onSubmit={handleSubmit(sendForm)}
      className='form--default'
      name='contact_specialist'
    >
      <S.FormTitle>Preencha o formulário para receber um contato de nosso especialista</S.FormTitle>
      <div className='form-body'>
        <div className='radioDiv'>
          <label>Já é cliente Inter?</label>
          <div className='d-flex flex-col'>
            <div className='radio'>
              <Radio
                id='radio-1'
                value='true'
                name='radio'
                checked={isClienteInter}
                onChange={() => setIsClienteInter(true)}
              />
              <label htmlFor='radio-1'>
                Sim
              </label>
            </div>
            <div className='radio'>
              <Radio
                id='radio-2'
                value='false'
                name='radio'
                checked={!isClienteInter}
                onChange={() => setIsClienteInter(false)}
              />
              <label htmlFor='radio-2'>
                Não
              </label>
            </div>
          </div>
        </div>
        <Input
          className='col-12 input'
          id='nome'
          placeholder='Digite o seu Nome'
          label='Nome'
          name='nome'
          ref={register({
            required: 'Por favor, digite seu nome completo',
            validate: {
              isName: (value: string) => Validations.name(value) || 'Por favor, digite seu nome completo',
            },
          })}
        />
        {errors.nome && <S.ErrorMessage>{errors.nome.message}</S.ErrorMessage>}
        <Input
          className='col-12 input'
          id='telefone'
          placeholder='Digite o seu Telefone'
          label='Telefone'
          name='telefone'
          ref={register({
            required: 'Digite um Telefone' || true,
            pattern: {
              value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
              message: 'Insira um número de telefone válido',
            },
          })}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const maskValue = Masks.MaskPHONE(event.currentTarget.value)
            setValue('telefone', maskValue, { shouldValidate: true })
          }
          }
        />
        {errors.telefone && <S.ErrorMessage>{errors.telefone.message}</S.ErrorMessage>}
        <Input
          className='col-12 input'
          id='email'
          placeholder='Digite o seu E-mail'
          label='E-mail'
          name='email'
          ref={register({
            required: 'Digite um E-mail válido' || true,
            validate: {
              isEmail: (value: string) => Validations.isEmail(value) || 'Digite um E-mail válido',
            },
          })}
        />
        {errors.email && <S.ErrorMessage>{errors.email.message}</S.ErrorMessage>}
        <Input
          className='col-12 input'
          id='cnpj'
          placeholder='Digite o seu CNPJ'
          label='CNPJ'
          name='cnpj'
          ref={register({
            required: 'Digite um CNPJ' || true,
            pattern: {
              value: /^(\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2})$/,
              message: 'Insira um CNPJ válido',
            },
          })}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const maskValue = Masks.MaskCNPJ(event.currentTarget.value)
            setValue('cnpj', maskValue, { shouldValidate: true })
          }}
        />
        {errors.cnpj && <S.ErrorMessage>{errors.cnpj.message}</S.ErrorMessage>}
        <Input
          className='col-12 input'
          id='faturamento'
          placeholder='Digite o seu faturamento mensal'
          label='Faturamento'
          name='faturamento'
          ref={register({
            required: 'Digite um Faturamento válido' || true,
            pattern: {
              value: /^(R\$)?\s?(\d{1,3}(\.\d{3})*|\d+)(,\d{2})?$/,
              message: 'Digite um faturamento válido',
            },
          })}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const formatedValue = formatStringAsCurrency(event.currentTarget.value)
            setValue('faturamento', formatedValue, { shouldValidate: true })
          }}
        />
        {errors.faturamento && <S.ErrorMessage>{errors.faturamento.message}</S.ErrorMessage>}

        <div className='my-4'>
          <AcceptTerms
            label='Autorizo o Inter a tratar meus dados pessoais para envio de comunicações sobre seus produtos e serviços e também estou de acordo com a '
            accept={aceite}
            setAccept={(value: boolean) => setAceite(value)}
            name='acceptTerm'
          />
        </div>
        <Button
          as='button'
          isLoading={loading}
          fullWidth
          type='submit'
          disabled={!aceite}
          onClick={() => {
            sendDatalayerEvent({
              section: 'modal',
              element_action: 'click button',
              element_name: 'Continue',
              section_name: 'Preenche o formulario para nosso especialista entrar em contato com você.',
            })
          }}
        >
          Continuar
        </Button>
        <S.LinkButton
          href='https://inter.co/empresas/conta-digital/pessoa-juridica/'
          onClick={() => {
            sendDatalayerEvent({
              section: 'modal',
              element_action: 'click button',
              section_name: 'Preenche o formulario para nosso especialista entrar em contato com você.',
              element_name: 'Saiba mais sobre a Conta Digital PJ',
              redirect_url: 'https://inter.co/empresas/conta-digital/pessoa-juridica/',
            })
          }}
        >
          Saiba mais sobre a Conta Digital PJ
        </S.LinkButton>
      </div>
    </form>
  )

  const successScreen = sent && (
    <div className='row'>
      <S.SuccessScreenSec>
        <OrangeDSIcon
          icon='check-circle'
          size='XL'
          color='green'
        />
        <h5>Prontinho! Recebemos os seus dados.</h5>
      </S.SuccessScreenSec>
    </div>
  )

  const errorScreen = error && (
    <div className='row'>
      <S.ErrorScreenSec>
        <OrangeDSIcon
          icon='canceled'
          size='XL'
          color='red'
        />
        <h5>Erro! Por favor tente enviar novamente</h5>
        <Button
          className='tryAgainButton'
          fullWidth
          onClick={() => {
            clearForm()
            sendDatalayerEvent({
              section: 'modal',
              element_action: 'click button',
              section_name: 'Preenche o formulario para nosso especialista entrar em contato com você.',
              element_name: 'Tentar novamente',
            })
          }}
        >
          Tentar novamente
        </Button>
        <Button
          variant='secondary'
          fullWidth
          onClick={() => {
            clearForm()
            closeModal()
            sendDatalayerEvent({
              section: 'modal',
              element_action: 'click button',
              section_name: 'Preenche o formulario para nosso especialista entrar em contato com você.',
              element_name: 'Fechar',
            })
          }}
        >
          Fechar
        </Button>
      </S.ErrorScreenSec>
    </div>
  )

  return (
    <S.Container className='d-flex align-items-center'>
      {closeButton}
      <div className='row'>
        <div className='col-12'>
          {formulario}
          {successScreen}
          {errorScreen}
        </div>
      </div>
    </S.Container>
  )
}

export default NewContactForm
