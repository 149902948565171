import { initTheme, Theme } from '@interco/inter-ui'
import React, { useEffect, useState } from 'react'
import { Modal } from 'src/components/Modal'
import useUtms from 'src/hooks/useUtms'
import useDomReady from 'src/hooks/window/useDomReady'
import Layout from 'src/layouts/BaseLayout'
import PageContext from './pageContext.json'
import { Wrapper } from './styles'

import NewContactForm from './components/ContactForm/_newContactForm'

import {
  AlgumasOutrasIntegracoes,
  APIParaCobranca,
  ApiPixInterEmpresas,
  EmpreendaComUmaSolucao,
  Hero,
  SeuCaixaMaisTransparente,
} from './sections/_index'

const ApiPix = () => {
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const domReady = useDomReady()

  const utmLink = useUtms({ link: `${process.env.OPEN_ACCOUNT_PJ}`, campanha: '' })

  const openAccountPJFormModal = () => {
    window.location.href = utmLink
  }

  useEffect(() => {
    initTheme(Theme.PF)
  }, [])

  const openModal = domReady && (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      locationToRender={document.body}
    >
      <NewContactForm
        closeModal={() => setIsModalOpen(false)}
      />

    </Modal>
  )
  return (
    <Wrapper>
      {openModal}
      <Layout
        handleHeaderOpenAccount={() => openAccountPJFormModal()}
        pageContext={PageContext}
      >
        <Hero />
        <EmpreendaComUmaSolucao />
        <ApiPixInterEmpresas setModalOpen={setIsModalOpen} />
        <APIParaCobranca />
        <SeuCaixaMaisTransparente />
        <AlgumasOutrasIntegracoes setModalOpen={setIsModalOpen} />
      </Layout>
    </Wrapper>
  )
}

export default ApiPix
